import "bootstrap/dist/css/bootstrap.min.css"
import "react-calendar/dist/Calendar.css"
import "./App.css"
import { BrowserRouter, Routes, Route, useNavigate, useParams } from "react-router-dom"
import MyNavbar from "./components/MyNavbar"
import NotFoundPage from "./components/NotFoundPage"
import Suggestions from "./components/Suggestions"
import BackOffice from "./components/BackOffice"
import Home from "./components/Home"
import Estate from "./components/Estate"
import { useEffect, type ReactNode } from "react"
import i18n from "./i18n"

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <MyNavbar />
        <Routes>
          <Route path="*" element={<NotFoundPage />} />
          <Route
            path="/:lang?"
            element={
              <LanguageWrapper>
                <Home />
              </LanguageWrapper>
            }
          />
          <Route
            path="/:lang?/estate"
            element={
              <LanguageWrapper>
                <Estate />
              </LanguageWrapper>
            }
          />
          <Route
            path="/:lang?/menorca"
            element={
              <LanguageWrapper>
                <Suggestions />
              </LanguageWrapper>
            }
          />
          <Route
            path="/:lang?/backOffice"
            element={
              <LanguageWrapper>
                <BackOffice />
              </LanguageWrapper>
            }
          />
        </Routes>
      </BrowserRouter>
    </div>
  )
}

// funciona pero falta pulir, si cambias varias veces el idioma, no se cambia la web si no usas F5
const LanguageWrapper = ({ children }: { children: ReactNode }) => {
  const { lang: paramsLang } = useParams<{ lang?: string }>()
  const navigate = useNavigate()

  useEffect(() => {
    const currentLang = i18n.language.split("-")[0] // Normaliza el idioma, por ejemplo "es-ES" -> "es"

    if (paramsLang && paramsLang !== currentLang) {
      // Si el idioma en la URL es diferente al actual, cámbialo
      i18n.changeLanguage(paramsLang)
    } else if (!paramsLang) {
      const currentPath = window.location.pathname
      const langInPath = currentPath.split("/")[1] // Extrae el primer segmento de la URL

      if (langInPath === currentLang) {
        // Si el idioma ya está en la URL, no redirijas
        return
      }

      if (currentPath === "/" || langInPath === "") {
        // Si estamos en la raíz ("/"), redirige al idioma normalizado
        navigate(`/${currentLang}`, { replace: true })
      } else {
        // Si no, añade el idioma normalizado a la ruta actual
        navigate(`/${currentLang}${currentPath}`, { replace: true })
      }
    }
  }, [paramsLang, navigate])

  return <>{children}</>
}

export default App
