import { Container, Button, Row, Col } from "react-bootstrap"
import { useNavigate } from "react-router-dom"
import errorImage from "../assets/404.avif" // Puedes colocar una imagen de error aquí

const NotFoundPage = () => {
  const navigate = useNavigate()

  return (
    <Container className="d-flex flex-column justify-content-center align-items-center" style={{ minHeight: "80vh" }}>
      <Row className="text-center mb-4">
        <Col>
          <h1 style={{ fontSize: "3rem", fontWeight: "bold", color: "#333" }}>Oops! Page not found</h1>
          <p style={{ fontSize: "1.25rem", color: "#555" }}>Sorry, the page you're looking for doesn't exist or has been moved.</p>
        </Col>
      </Row>

      <Row className="mb-5">
        <Col className="text-center">
          <img src={errorImage} alt="404 error" style={{ maxWidth: "100%", height: "auto", maxHeight: "300px" }} />
        </Col>
      </Row>

      <Row>
        <Col className="text-center">
          <Button
            variant="primary"
            size="lg"
            onClick={() => navigate("/")}
            style={{
              padding: "10px 30px",
              fontSize: "1.1rem",
              borderRadius: "25px",
              backgroundColor: "rgb(166, 61, 111)",
              boxShadow: "0 4px 10px rgba(0, 123, 255, 0.3)",
              color: "white",
            }}
          >
            Go Back to Homepage
          </Button>
        </Col>
      </Row>
    </Container>
  )
}

export default NotFoundPage
