import { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import ICAL from "ical.js"
import Calendar from "react-calendar"
import calendarIcon from "../assets/icons/calendar.svg"
import bedIcon from "../assets/icons/bed.svg"
import peopleIcon from "../assets/icons/people.svg"
import { Dropdown, Container, Row, Col } from "react-bootstrap"
import i18n from "../i18n"
import ReservationSummary from "./ReservationSummary"

interface CalendarDialogProps {
  closeModal: () => void
}

const CalendarDialog: React.FC<CalendarDialogProps> = ({ closeModal }) => {
  // const [active, setActive] = useState<"left" | "right" | null>(null)
  const [events, setEvents] = useState<IEvent[]>([])
  const [isOpen, setIsOpen] = useState(false)
  const [checkIn, setCheckIn] = useState<Date | null>(null)
  const [checkOut, setCheckout] = useState<Date | null>(null)
  const today = new Date()
  const nextMonth = today.getMonth() === 11 ? new Date(today.getFullYear() + 1, 0, 1) : new Date(today.getFullYear(), today.getMonth() + 1, 1)
  const [guests, setGuests] = useState(0)
  const [view, setView] = useState<"calendar" | "summary">("calendar")

  const { t } = useTranslation()

  const handleDateClick = (selectedDate: Date) => {
    if (!checkIn || (checkIn && checkOut)) {
      // Si no hay check-in o ya hay ambos, reiniciar
      setCheckIn(selectedDate)
      setCheckout(null)
    } else if (checkIn && !checkOut) {
      // Si ya hay check-in pero no check-out
      if (selectedDate > checkIn) {
        setCheckout(selectedDate) // Fecha válida como check-out
      } else {
        setCheckIn(selectedDate) // Si selecciona una fecha anterior, es el nuevo check-in
      }
    }
  }

  useEffect(() => {
    fetch("http://localhost:3001/calendar")
      .then((response) => response.text())
      .then((data) => {
        try {
          const jcalData = ICAL.parse(data)
          const comp = new ICAL.Component(jcalData)
          const vevents = comp.getAllSubcomponents("vevent")
          const parsedEvents = vevents.map((vevent: any) => {
            const event = new ICAL.Event(vevent)
            return {
              summary: event.summary,
              startDate: event.startDate.toJSDate(),
              endDate: event.endDate.toJSDate(),
            }
          })
          setEvents(parsedEvents)
        } catch (parseError) {
          console.error("Error parsing calendar data:", parseError)
        }
      })
      .catch((error) => console.error("Error fetching calendar:", error))
  }, [])

  const tileDisabled = ({ date }: { date: Date }) => {
    // return events.some((event) => date >= new Date(event.startDate) && date < new Date(event.endDate))
    return date < today || events.some((event) => date >= new Date(event.startDate) && date < new Date(event.endDate))
  }

  const tileClassName = ({ date }: { date: Date }) => {
    if (checkIn && date.toDateString() === checkIn.toDateString()) {
      return "selected-checkin selected-checkin-shadow" // Clase para check-in
    }
    if (checkOut && date.toDateString() === checkOut.toDateString()) {
      return "selected-checkout" // Clase para check-out
    }
    if (checkIn && checkOut && date > checkIn && date < checkOut) {
      return "selected-range" // Clase para rango seleccionado
    }
    return ""
  }

  const handleDropdownToggle = (isOpen: boolean) => {
    setIsOpen(isOpen)
  }

  const handleGuestSelection = (guestCount: number) => {
    setGuests(guestCount)
  }
  // const handleDateNavFocus = (side: "left" | "right") => {
  //   setActive(side)
  // }
  return (
    <>
      {view === "calendar" && (
        <Container className="mx-auto p-5">
          <button
            onClick={closeModal}
            style={{
              position: "absolute",
              top: "16px",
              right: "16px",
              background: "none",
              border: "none",
              fontSize: "24px",
              cursor: "pointer",
            }}
          >
            ✕
          </button>
          <Row className="justify-content-center">
            <div>
              <img src={calendarIcon} alt="Calendar Icon" style={{ width: "20px", height: "20px", verticalAlign: "sub" }} />
              <span className="ml-2 calendarTitle">{t("calendar.calendar")}</span>
            </div>
          </Row>

          {/* GuestSelector */}
          <Row>
            <Dropdown className="w-100" show={isOpen} onToggle={handleDropdownToggle}>
              <Dropdown.Toggle variant="transparent" className="w-100">
                <div className="d-flex justify-content-between align-items-center w-100">
                  <div>
                    <img src={peopleIcon} className="mr-2" alt="people" style={{ width: "20px", height: "20px", verticalAlign: "sub" }} />
                    <span>{guests || t("calendar.guests")}</span>
                  </div>
                  {isOpen ? (
                    <svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M0.706948 6.28271L5.64645 1.75484C5.74151 1.6712 5.86818 1.62449 5.99995 1.62449C6.13172 1.62449 6.25838 1.6712 6.35345 1.75484L11.2899 6.28042L11.9969 5.63234L7.06045 1.10676C6.77452 0.856956 6.39469 0.717608 5.9997 0.717608C5.60471 0.717608 5.22487 0.856956 4.93895 1.10676L-5.14984e-05 5.63463L0.706948 6.28271Z"
                        fill="#282828"
                      />
                    </svg>
                  ) : (
                    <svg width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M11.2929 2.71729L6.35343 7.24516C6.25836 7.3288 6.1317 7.37551 5.99993 7.37551C5.86816 7.37551 5.7415 7.3288 5.64643 7.24516L0.70993 2.71958L0.00292969 3.36766L4.93943 7.89324C5.22536 8.14304 5.60519 8.28239 6.00018 8.28239C6.39517 8.28239 6.775 8.14304 7.06093 7.89324L11.9999 3.36537L11.2929 2.71729Z"
                        fill="#282828"
                      />
                    </svg>
                  )}
                </div>
              </Dropdown.Toggle>

              <Dropdown.Menu className="w-100">
                {[1, 2, 3, 4].map((guestCount) => (
                  <Dropdown.Item key={guestCount} onClick={() => handleGuestSelection(guestCount)}>
                    {/* {`${guestCount} ${guestCount === 1 ? t("calendar.guest") : t("calendar.guests")}`} */}
                    {guestCount}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </Row>

          {/*  DatesNav */}
          <Row className="justify-content-center my-3">
            <div className="checkinoutbox">
              {/* <Col className={`checkin-col ${active === "left" ? "active" : ""}`} onClick={() => handleDateNavFocus("left")}> */}
              <Col className="ml-2">
                <div>
                  <p className="mb-1 text0-875os weight-600">Check in</p>
                  <p className="mb-1 ">{checkIn?.toLocaleDateString() || t("calendar.adddates")}</p>
                </div>
              </Col>
              <div className="divider" />
              {/* <Col className={`checkout-col ${active === "right" ? "active" : ""}`} onClick={() => handleDateNavFocus("right")}> */}
              <Col>
                <div>
                  <p className="mb-1 text0-875os weight-600">Check out</p>
                  <p className="mb-1 ">{checkOut?.toLocaleDateString() || t("calendar.adddates")}</p>
                </div>
              </Col>
            </div>
          </Row>

          {/* Single calendar */}
          <Row className="justify-content-center d-lg-none">
            <Calendar
              className="calendarOnlyOne"
              onClickDay={handleDateClick}
              value={[checkIn, checkOut]}
              locale={i18n.language}
              tileDisabled={tileDisabled}
              tileClassName={tileClassName}
              view="month"
              showNeighboringMonth={false}
              minDate={today}
            />
          </Row>

          {/* Double calendar */}
          <Row className="justify-content-center d-none d-lg-flex">
            <Calendar
              className="calendarLeft"
              onClickDay={handleDateClick}
              value={[checkIn, checkOut]}
              locale={i18n.language}
              tileDisabled={tileDisabled}
              tileClassName={tileClassName}
              view="month"
              showNeighboringMonth={false}
              minDate={today}
            />

            <Calendar
              className="calendarRight"
              onClickDay={handleDateClick}
              value={[checkIn, checkOut]}
              locale={i18n.language}
              tileDisabled={tileDisabled}
              tileClassName={tileClassName}
              view="month"
              showNeighboringMonth={false}
              minDate={nextMonth}
            />
          </Row>

          <Row className="justify-content-end mt-3">
            <button className="requestButton py-1 px-4" onClick={() => setView("summary")} disabled={!checkIn || !checkOut || guests <= 0}>
              <img src={bedIcon} className="mr-2" alt="Bed Icon" style={{ width: "20px", height: "20px" }} /> {t("calendar.request")}
            </button>
          </Row>
        </Container>
      )}

      {/* Summary Modal */}
      {view === "summary" && (
        <Container>
          <ReservationSummary checkIn={checkIn} checkOut={checkOut} guests={guests} onBack={() => setView("calendar")} closeModal={closeModal} />
        </Container>
      )}
    </>
  )
}

export default CalendarDialog
