import React, { useState } from "react"
import { useTranslation } from "react-i18next"

interface ReservationSummaryProps {
  checkIn: Date | null
  checkOut: Date | null // nunca seran null
  guests: number
  onBack: () => void
  closeModal: () => void
}

const ReservationSummary: React.FC<ReservationSummaryProps> = ({ checkIn, checkOut, guests, onBack, closeModal }) => {
  const [observations, setObservations] = useState("")
  const { t } = useTranslation()

  return (
    <>
      <button
        onClick={onBack}
        style={{
          position: "absolute",
          top: "16px",
          left: "16px",
          background: "none",
          border: "none",
          fontSize: "24px",
          cursor: "pointer",
        }}
      >
        ←
      </button>
      <button
        onClick={closeModal}
        style={{
          position: "absolute",
          top: "16px",
          right: "16px",
          background: "none",
          border: "none",
          fontSize: "24px",
          cursor: "pointer",
        }}
      >
        ✕
      </button>

      <h3 style={{ textAlign: "center", marginBottom: "32px" }}>{t("calendar.summary.title1")}</h3>
      <div style={{ marginBottom: "32px" }}>
        <p>
          <strong>{t("calendar.summary.guests")}:</strong> {guests}
        </p>
        <p>
          <strong>Check-in:</strong> {checkIn?.toLocaleDateString()}
        </p>
        <p>
          <strong>Check-out:</strong> {checkOut?.toLocaleDateString()}
        </p>
      </div>

      {/* <div className="divider" /> */}

      <h3 style={{ textAlign: "center", marginBottom: "32px" }}>{t("calendar.summary.title2")}</h3>
      <div style={{ display: "flex", gap: "16px" }}>
        {/* Columna izquierda */}
        <div style={{ flex: 1 }}>
          <p>
            <strong>{t("calendar.summary.name")}:</strong>
          </p>
          <input type="text" placeholder="Introduce tu nombre" className="form-control" />

          <p>
            <strong>{t("calendar.summary.mail")}:</strong>
          </p>
          <input type="email" placeholder="Introduce tu email" className="form-control mb-2" />

          <p>
            <strong>{t("calendar.summary.phone")}:</strong>
          </p>
          <input type="tel" placeholder="Introduce tu teléfono" className="form-control mb-2" />
        </div>

        {/* Columna derecha */}
        <div style={{ flex: 1 }}>
          <p>
            <strong>{t("calendar.summary.phone")}:</strong>
          </p>
          <textarea
            maxLength={150}
            rows={6}
            placeholder="Escribe tus observaciones"
            className="form-control"
            onChange={(e) => setObservations(e.target.value)}
          />
        </div>
      </div>
    </>
  )
}

export default ReservationSummary
